import React from "react";
import {Header} from "../components/LayoutComponents";

export default function resume(){
    return (
        <div>
            <Header />
            <div className="midsectiontext">
                <h1>Resume</h1>
            </div>
        </div>
    );
}