import React from "react";
import { Link } from "react-router-dom";
import {Row, Box} from './components/LayoutComponents'

// function Row({className, children}){
//     return <div className={className}>{children}</div>;
// }

// function Box({className, children, style}){
//     return <div className={className} style={{...style}}>{children}</div>
// }

export default function Page(){
    return (
        <div style={{height:'1000px'}}>
            <div className="content"> 
                <Row className={"content-row"}>
                    <Box style={{width: '20%'}}><img src="media/cropped.png" className="image"/></Box>
                    <Box style={{width: '30%'}} className={"text"}><br></br>Hi, I'm Patrick <br></br><br></br>Below is my portfolio</Box>
                    <Box style={{width: '20%'}} className={"text"} >
                        <div style={{marginTop: '8px'}}>
                            <a href="/about">&gt; About Me</a> <br/><br/>
                            <a href="/resume">&gt; Resume</a><br/><br/>
                            <a href="/contact">&gt; Contact</a>
                        </div>
                    </Box>
                </Row>
                <Row className={"content-row"}>
                    <Box style={{width: '43%'}}><img src="media/Rabbit_Simulator.png" className="image"/>
                    </Box>
                    <Link to="/runrabbitrun" style={{width: '43%'}}>
                        <Box className={"text"}>
                                <h1>&lt; Run Rabbit Run</h1>
                                <h2>C#</h2>
                                <h3>Will faster rabbits outcompete their slower counterparts, even though they use additional energy?</h3>
                                <h3>This is an ecosystem simulator I created based upon my studies of population dynamics during my biology degree.</h3>
                        </Box>
                    </Link>
                </Row>
                <Row className={"content-row"}>
                    <Box style={{width: '22%'}}><img src="media/Western_Blot.png" className="image"/></Box>
                    <Link to="/virtuallabs" style={{width: '32%'}}>
                        <Box className={"text"}>
                        <h1>&lt; Virtual Laboratories &gt;</h1>
                        <h2>C#</h2>
                        <h3>Can STEM students learning remotely have worthwhile online laboratory experiences?</h3>
                        <h3>Written for my honours thesis, these data generating virtual labs aim to give remote learners an involved lab experience.</h3>
                        </Box>
                    </Link>
                    <Box style={{width: '22%'}}><img src="media/dotblot.png" className="image"/></Box>
                </Row>
                <Row className={"content-row"}>
                    <Box style={{width: '43%'}}>
                        <div className="minecraftimage">
                            <img src="media/lastmutton.jpeg" width={"100%"} height={"150%"}/>
                        </div>
                    </Box>
                    <Link to="/minecraft" style={{width: '43%'}}>
                        <Box className={"text"}>
                        <h1>&lt; Minecraft Server Dashboard</h1>
                        <h2>Assorted AWS</h2>
                        <h3>The children yearn for the mines.</h3>
                        <h3>A handy little dashboard to monitor and control my minecraft server.</h3>
                        </Box>
                    </Link>
                </Row>
                <Row className={"content-row"}>
                    <Box style={{width: '22%'}}><img src="media/Western_Blot.png" className="image"/></Box>
                    <Box style={{width: '32%'}} className={"text"}>
                        <h1>&lt; Placeholder Content 2&gt;</h1>
                        <h2>C#</h2>
                        <h3>yada yada yada</h3>
                        <h3>Lorem Ipsum, etc</h3>
                    </Box>
                    <Box style={{width: '22%'}}><img src="media/dotblot.png" className="image"/></Box>
                </Row>
            </div>
        </div>
    );
}