import React from "react";

export function Header(){
    return (
        <div  className="header">
            <div  className="container">
                <a  className = "left one" href="/">Patrick Coleman</a>
                <a  className = "right one" href="/about">About</a>
                <a  className = "right one" href="/resume">Resume</a>
                <a  className = "right one" href="/contact">Contact</a>
            </div>
        </div>
      );
}

export function Row({className, children}){
    return <div className={className}>{children}</div>;
}

export function Box({className, children, style}){
    return <div className={className} style={{...style}}>{children}</div>
}
