import React from "react";
import {Header} from "../components/LayoutComponents";

export default function About(){
    return (
        <div>
            <Header />
            <div className="midsectiontext">
                <h1>About goes here</h1>
                <a className = "two" href="/rabbit_simulator">Rabbit Simulator</a>
            </div>
        </div>
    );
}