import React, { useState, useEffect } from "react";
import {Header, Box, Row} from "../components/LayoutComponents";

export default function Minecraft(){
    const [serverState, setServerState] = useState('Not found');
    const [serverStatus, setServerStatus] = useState('Loading');
    const [playersOnline, setPlayersOnline] = useState('Loading')

    useEffect(() => {
        const interval = setInterval(() => {
            GetState(setServerState);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (serverState === 'running') {
            setServerStatus('Online');
        } else if (serverState === 'Instance status not available') {
            setServerStatus('Offline');
        } else {
            setServerStatus('Unknown');
        }
    }, [serverState]);

    useEffect(() => {
        GetPlayers(setPlayersOnline);
    }, []);
    
    return (
        <div>
            <Header />
            <div style={{height:'1000px'}}>
                <div className="content"> 
                    <Row className={"content-row"}>
                        <Box style={{width: '20%'}} className={"text"}>
                            Server Status:<br></br>{serverStatus}
                            <br></br>
                            <br></br>
                            <StartServerButton serverStatus={serverStatus}/>
                        </Box>
                        <Box style={{width: '30%'}} className={"text"}><br></br> Minecraft Server Manager <br></br><br></br>Built using AWS</Box>
                        <Box style={{width: '20%'}} className={"text"}>
                            <div style={{marginTop: '8px'}}>
                                Players Online: <br></br> {PlayerCountText(playersOnline,serverStatus)}
                            </div>
                        </Box>
                    </Row>
                </div>
            </div>
        </div>
    );
}

function PlayerCountText(players, serverstatus){
    if (serverstatus != 'Online'){
        return '\n n/a \n server offline'
    } else {
        return players
    }
}

function GetPlayers(setPlayersOnline){
    fetch('https://yaijiztcw7.execute-api.ap-southeast-2.amazonaws.com/prod/checkplayers')
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            const bodyJson = JSON.parse(data["body-json"]);
            setPlayersOnline(bodyJson.playersonline);
        })
        .catch(error => {
            console.error('Error:', error);
            // alert('An error occurred while trying to retrieve the server state');
        });
}

function StartServer(){
    fetch(`https://yaijiztcw7.execute-api.ap-southeast-2.amazonaws.com/prod/start`)
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            alert('Server started successfully!');
        })
        .catch(error => {
            console.error('Error:', error);
            alert('An error occurred while starting the server, sorry about that!');
        });
}

function GetState(setServerState){
    fetch('https://yaijiztcw7.execute-api.ap-southeast-2.amazonaws.com/prod/checkstate')
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            const bodyJson = JSON.parse(data["body-json"]);
            setServerState(bodyJson.instanceState);
        })
        .catch(error => {
            console.error('Error:', error);
            // alert('An error occurred while trying to retrieve the server state');
        });
}

function StartServerButton({serverStatus}){
    if (serverStatus === 'Offline'){
        return  <button onClick={StartServer} className='text' style={{ height:50 }}>
                    Launch Server
                </button>
    } else {
        return null;
    }
}
