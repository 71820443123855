import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import ".//styles/styles.css";
import App from "./App";
import About from "./pages/about"
import Resume from "./pages/resume"
import Contact from "./pages/contact"
import Minecraft from "./pages/minecraft";
import Virtuallabs from "./pages/virtuallabs";
import Runrabbitrun from "./pages/runrabbitrun";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>
  },
  {
    path:"/about",
    element:<About/>
  },
  {
    path:"/resume",
    element:<Resume/>
  },
  {
    path:"/contact",
    element:<Contact/>
  },
  {
    path:"/minecraft",
    element:<Minecraft/>
  },
  {
    path:"/virtuallabs",
    element:<Virtuallabs/>
  },
  {
    path:"/runrabbitrun",
    element:<Runrabbitrun/>
  }
])

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)